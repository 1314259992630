import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/good-place/good_place_img1.png'
import img2 from '../../assets/images/good-place/good_place_img2.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionFullImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import { HeadlineM, TitleM, ParagraphL, ParagraphM, ParagraphS } from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Oglaševanje na družbenih omrežjih',
    'Google oglasi',
    'Grafično oblikovanje',
    'E-mail oglaševanje',
    'Oglaševanje na družbenih omrežjih',
    'Google oglasi',
    'Grafično oblikovanje',
    'E-mail oglaševanje',
    'Oglaševanje na družbenih omrežjih',
    'Google oglasi',
    'Grafično oblikovanje',
    'E-mail oglaševanje',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | GoodPlace"
        description="Agencija Visit GoodPlace je naš stalni patner vse od leta 2019. Skrbimo za veliko večino marketinških aktivnosti v podjetju in skupaj z naročnikom nenehno iščemo nove možnosti za rast."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="GoodPlace" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>Visit GoodPlace & Zavod GoodPlace</ParagraphM>
            <HeadlineM color={colors.purple}>GoodPlace</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Vzpostaviti sistem marketinških aktivnosti, ki bo tuje goste prepričal, da se odločijo
              za aktivne počitnice v Sloveniji, ki jih organizira slovenska butična outdoor
              agencija.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Agencija Visit GoodPlace je naš stalni patner vse od leta 2019. Skrbimo za veliko
              večino marketinških aktivnosti v podjetju in skupaj z naročnikom nenehno iščemo nove
              možnosti za rast.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding reduce-bottom-padding">
            <Content>
              {/* Example Title */}
              <h2>Več kot naročniki</h2>

              {/* Example Paragraph */}
              <p>
                S podjetjem Visit GoodPlace in Zavodom GoodPlace sodelujemo od leta 2019. Ekipi
                nudimo celostno podporo na področju marketinga.
              </p>
              <p>
                Skrbimo za urejanje družbenih omrežij, promocijo na družbenih omrežjih, Google
                oglaševanje, e-mail marketing, grafično oblikovanje, redno posodabljanje spletne
                strani in nadgrajevanje uporabniške izkušnje na strani.
              </p>
              <p>
                Z GoodPlace ekipo sodelujemo na dnevni ravni in redno iščemo in testiramo nove
                ideje, ki bi lahko prinesle še boljše rezultate.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="GoodPlace" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Paragraph */}
              <p>Fotografije so last Visit GoodPlace (foto: Žiga Koren)</p>
            </Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/coronini-caffee">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/mlada-pobuda">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
